<template>
  <div>
    <div class="card">
      <Toast position="top-center" group="info" />
      <h5>Запросы на выпуск ЦФА</h5>
      <hr/>
      <DataTable
        :value="issuers"
        dataKey="id"
        v-model:issuers="issuers"
        responsiveLayout="scroll"
        :sortable="true"
      >
        <Column
          header="Актив"
          field="tokenName"
          style="word-break: break-all"
        />
        <Column
          header="Абр"
          field="tokenMnemonic"
          style="word-break: break-all"
        />
        <Column
          header="Кол"
          field="tokenCount"
          style="word-break: break-all"
        />
        <Column header="Статус" style="word-break: break-all">
          <template #body='slot'>
            {{ slot.data.confirmedAt == null ? 'Ожидание' : 'Исполнен'}}
          </template>
        </Column>
        <Column
          class="bg-white border-none flex flex-nowrap justify-content-end"
        >
          <template style="width: 130px" #body="slot">
            <Button
              label='Утвердить запрос'
              icon="pi pi-check"
              class="p-button-rounded p-button-success m-1"
              @click="showBlockChainDialog(slot.data)"
            />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>

  <Dialog
    v-model:visible='blockchainDialog'
    header='Утвердить запрос'
    class='p-fluid'
  >
    <label for='blockchainList'>Выберите необходимый блокчейн</label>
    <Dropdown
      id='blockchainList'
      v-model='selectedBlockchain'
      :options='blockchainList'
      option-label='name'
    />
    <br>
    <label for='decimals'>Количество знаков после запятой</label>
    <InputText id='decimals' v-model='decimals' placeholder='Знаков после запятой' type='number'/>
    <br><br>
    <Button
      :disabled='!selectedBlockchain'
      label='Ок'
      class='p-button-primary'
      @click='confirm'
    />
  </Dialog>
</template>

<script>
import moment from 'moment/moment'

export default {
  data(){
    return{
      issuers: [],
      blockchainDialog: false,
      blockchainList: [],
      selectedBlockchain: null,
      selectedOrderId: 0,
      decimals: null,
    }
  },

  async mounted() {
    this.getRequestToIssues();
    this.getBlockChainList();
  },

  methods:{
    async getRequestToIssues()
    {
      const headers = {
        accept: 'application/json',
      }
      try
      {
        const resp = await this.axios.get('api/request_to_issues', headers);
        const allData = resp.data;
        for (const item of allData)
        {
          if (item.confirmedAt == null) this.issuers.push(item);
        }
      }
      catch (e)
      {
        console.log('err', e);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Request not created',
          group: 'info',
          life: 3000,
        });
      }
    },
    async confirm()
    {
      const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
      }
      const currentDate = moment();
      const params = {
        isDraft: false,
        confirmedAt: currentDate,
        blockChain: `/api/block_chains/${this.selectedBlockchain.id}`,
        decimals: Number(this.decimals)
      }
      try
      {
        await this.axios.put(`api/request_to_issues/${this.selectedOrderId}`, params, headers);
        this.$toast.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Request success',
          group: 'info',
          life: 3000,
        });
        for (let i = 0; i < this.issuers.length; i++)
        {
          if (this.issuers[i].id === this.selectedOrderId)
          {
            this.issuers.splice(i,1);
            break;
          }
        }
      }
      catch (e)
      {
        console.log('err', e);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Confirm request error',
          group: 'info',
          life: 3000 });
      }
    },
    async getBlockChainList()
    {
      try
      {
        const resp = await this.axios.get('api/block_chains')
        this.blockchainList = resp.data;
      }
      catch (e)
      {
        console.log('err', e);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Blockchain request error',
          group: 'info',
          life: 3000 });
      }
    },
    showBlockChainDialog(value)
    {
      this.selectedOrderId = value.id
      this.decimals = value.decimals
      this.blockchainDialog = true
    }
  },
}
</script>

<style scoped>

</style>